import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIStatus } from './APIStatus'

const initialState = {
    dashboardConfig : null,
    deviceFilters : null,
    deviceSummaryFilters : null,
    alarmsFilters : null,
    processedDataFilters : null,
    isLoading : 'loading'
}

export const getDashboardConfig = createAsyncThunk('getDashboardConfig', async (payload) => {
    const response = await axios.get(payload.url);
    return response;
})

const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        reset(state, action){
            state.deviceFilters = null
        },
        deviceFilterStore(state, action){
            state.deviceFilters = action.payload
        },
        deviceSummaryFilterStore(state, action){
            state.deviceSummaryFilters = action.payload
        },
        alarmsFilterStore(state, action){
            state.alarmsFilters = action.payload
        } ,
        processedDataFilterStore(state, action){
            state.processedDataFilters = action.payload
        }  
    },
    extraReducers: builder => {
        builder
            .addCase(getDashboardConfig.pending, (state, action) => {
                state.isLoading = APIStatus.loading;
            })
            .addCase(getDashboardConfig.fulfilled, (state, action) => {
                state.isLoading = APIStatus.loaded;
                state.dashboardConfig = action.payload ? action.payload.data :  null;
            })
    }
})

export const { reset, deviceFilterStore, deviceSummaryFilterStore, alarmsFilterStore, processedDataFilterStore } = configurationSlice.actions

export default configurationSlice.reducer