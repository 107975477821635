import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    // height: "70vh",
    width: '50vw',
    display: 'flex',
    flexDirection: 'column',
   
    justifyContent: 'center',
    alignItems: 'center',
    // border: "2px solid blue",
    // margin: "auto",
    // position: "absolute",
    // top: 0,
    // left: 0, 
  },
  logotypeContainer: {
    //backgroundColor: theme.palette.primary.main,
    // width: "100%",
    // height: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ' 10px  20px 0px 20px',
    
    borderRadius: '10px',
  },
  logoImage: {
    width: 100,
    height: 100,
    marginBottom: theme.spacing(4),
    // [theme.breakpoints.down("md")]: {
    //   width: "60%",
    //   height:"30%"
    // },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      height: '15%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      height: '30%'
    },
  },
  logotypeImage: {
    width: 400,
    height: 200,
    marginBottom: theme.spacing(4),
    // [theme.breakpoints.down("md")]: {
    //   width: "60%",
    //   height:"30%"
    // },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      height: '15%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      height: '30%',
    },
  },
  smalllogotypeImage: {
    width: 30,
    height: 35,
    marginBottom: theme.spacing(1),
  },
  logotypeText: {
    color: 'white',
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down('md')]: {
      fontSize: 48,
    },
  },
  formContainer: {
    // width: "50%",
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '\'Karla\', sans-serif',

  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  greeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: 'white',
    width: '100%',
    textTransform: 'none',
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  createAccountButton: {
    height: 46,
    textTransform: 'none',
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: `${theme.palette.text.hint  }40`,
  },
  errorMessage: {
    textAlign: 'center',
  },
  textFieldUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '&:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&:hover:before': {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
     
  },
  forgetButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: theme.spacing(2),
    },
  },
}));
