import { makeStyles } from '@material-ui/styles';

import mainbg from './Waterbanner.jpeg';

export default makeStyles(theme => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: '60%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
      //height:"50%"
    },
    [theme.breakpoints.down('sm')]: {
      //display: "none",
      width: '50%',
      //height:"50%"
    },
    [theme.breakpoints.down('xs')]: {
      //display: "none",
      width: '50%',
      height: '50%'
    },
  },
  logotypeImage: {
    width: 400,
    height: 200,
    marginBottom: theme.spacing(4),
    // [theme.breakpoints.down("md")]: {
    //   width: "60%",
    //   height:"30%"
    // },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      height: '15%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      height: '30%'
    },
  },
  smalllogotypeImage: {
    width: 70,
    height: 80,
    marginBottom: theme.spacing(2),
  },
  logotypeText: {
    color: 'white',
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down('md')]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: '40%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '\'Karla\', sans-serif',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      height: '60%'
    },
  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  greeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: 'white',
    width: '100%',
    textTransform: 'none',
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  createAccountButton: {
    height: 46,
    textTransform: 'none',
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: `${theme.palette.text.hint  }40`,
  },
  errorMessage: {
    textAlign: 'center',
  },
  textFieldUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '&:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&:hover:before': {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  forgetButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyrightDiv: {
    marginTop: theme.spacing(2),
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color : '#23679a',
    fontSize : 16
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: theme.spacing(2),
    },
  },
  loginCard: {
    padding: 45,
    width: 400,
  },
  main: {
    background: '#00000088',
    backgroundImage: `url(${mainbg})`,
    maxWidth: '100vw',
    minHeight: '100vh',
    paddingTop: '10%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  signInBtn: {
    marginTop: 20,
    borderRadius: 20,
  },
  title: {
    fontWeight: 'bold'
  },
  siteLogo: {
    display: 'inline-flex',
    background: '#00000077',
    padding: '10px',
    borderRadius: '15px',
    position: 'absolute',
    top: 20
  },
  siteLogoIcon: {
    width: 50,
    height: 55,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  loader: {
    textAlign: 'center',
    marginTop: 20
  }
}));
