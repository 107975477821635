import {
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';

import { verifyEmailId } from '../../app/resetPasswordSlice';

import useStyles from './styles';
import icon from './icon.png';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const VerifyEmail = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  useEffect(() => {
    document.title = 'hydroEye - Verify Email Address';
  });

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const verifyEmailStatus = useSelector(
    (state) => state.resetPasswordSlice.verifyEmail
  );

  const verifyEmailError = useSelector(
    (state) => state.resetPasswordSlice.verifyEmailError
  );

  useEffect(() => {
    if (verifyEmailError !== null && isLoading) {
      setIsMessage(false);
      setIsError(true);
      setErrorMessage('An Error Occurred');
      setIsLoading(false);
      setTimeout(() => {
        history.push({
          pathname: '/login',
        });
      }, 5000);
    }
    // eslint-disable-next-line
  }, [verifyEmailError]);

  useEffect(() => {
    if (verifyEmailStatus !== null && isLoading) {
      setIsError(false);
      setIsMessage(true);
      setErrorMessage('Email verified successfully');
      setIsLoading(false);
      setTimeout(() => {
        history.push({
          pathname: '/login',
        });
      }, 3000);
    }
    // eslint-disable-next-line
  }, [verifyEmailStatus]);

  let locationInfo = location.pathname.split('/');
  let emailToken = locationInfo[2];
  const vertical = 'top';
  const horizontal = 'right';

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',

        // alignContent:"center",
        justifyContent: 'center',
      }}
    >
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isError}
          autoHideDuration={3000}
          onClose={() => setIsError(false)}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isMessage}
          autoHideDuration={3000}
          onClose={() => setIsMessage(false)}
        >
          <Alert severity="success">{errorMessage}</Alert>
        </Snackbar>
      </div>

      <Grid container className={classes.container} >
        <Grid className={classes.logotypeContainer}>
          <img src={icon} alt="logo" className={classes.logoImage} />
        </Grid>
        <Grid className={classes.formContainer}>

          <div className={classes.formButtons}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <p style={{ fontSize: '16px' }}>Click the below button to Verify Email address</p>

                <Button
                  onClick={() => {
                    setIsLoading(true);
                    dispatch(verifyEmailId(emailToken));
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Verify Email
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(VerifyEmail);
