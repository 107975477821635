
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialogContext = React.createContext();

const useConfirmDialog = () => {
    const context = React.useContext(ConfirmDialogContext);
    return context;
};

const rootReducer = (state, action) => {
    switch (action.type) {
        case "SHOW_DIALOG":
            return {
                content: action.payload.content,
                title: action.payload.title,
                open: true,
                onOk: action.payload.onOk,
                // onCancel: action.payload.onCancel
            };
        case "CLOSE_DIALOG":
            return {
                ...state,
                // content: '',
                // title: '',
                open: false,
                // onOk: null,
                // onCancel: null
            };
        case "CLEAR_DATA":
            return {
                content: '',
                title: '',
                open: false,
                onOk: null,
                // onCancel: null
            };
        default:
            return state;
    }
}

const ConfirmDialogProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(rootReducer, {
        content: '',
        title: '',
        open: false,
        onOk: null,
        // onCancel: null
    });

    const closeDialog = () => {
        dispatch({ type: "CLOSE_DIALOG" });
        const timer = setTimeout(() => {
            dispatch({ type: "CLEAR_DATA" });
            clearTimeout(timer);
          }, 100);
          
        
    }
    const showDialog = (dialogData) => {
        dispatch({ type: "SHOW_DIALOG", payload: dialogData });
    }

    const onOk = () => {
        closeDialog();
        state.onOk();
    }
    return (
        <ConfirmDialogContext.Provider value={{ showDialog, closeDialog }}>
            {children}

            <Dialog
                open={state.open}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{state.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {state.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onOk} variant="contained" color="secondary">
                        Delete
                    </Button>
                    <Button onClick={closeDialog} color="primary" variant="contained" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </ConfirmDialogContext.Provider>
    );
};

export { ConfirmDialogProvider, ConfirmDialogContext, useConfirmDialog };