
import detectLogo from "./detectronics/detectronics-logo.png";

export const whitelabelConfig = {
  'qa.detectronic.r-eye.io': {
    logo: detectLogo,
    logoWidth : 150,
    logoHeight : 80,
    name: 'Detectronic'
  }
};