import React, { Suspense, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useLayoutState } from "../../context/LayoutContext";
import { CircularProgress } from "@material-ui/core";
import {usePageTracking} from "./../../usePageTracking";
import { getDevicesHealth } from "../../app/devicesSlice";
import { useDispatch } from "react-redux";

const CustomDashboards = React.lazy(() => import('../../pages/custom-dashboards/CustomDashboards'));
const Dashboard = React.lazy(() => import('../../pages/dashboard/Dashboard'));
const Alarms = React.lazy(() => import('../../pages/alarms/Alarms'));
const Triggers =  React.lazy(() => import('../../pages/triggers/Triggers'));
const ProcessedData = React.lazy(() => import('../../pages/processedData/ProcessedData'));
const DeviceManagement = React.lazy(() => import('../../pages/device-management/DeviceManagement'));
const AddDevice = React.lazy(() => import('../../pages/device-management/AddDevice'));
const EditDevice = React.lazy(() => import('../../pages/device-management/EditDevice'));
const DeviceSummary =  React.lazy(() => import('../../pages/deviceSummary/DeviceSummary'));
const ChangePassword = React.lazy(() => import('../../pages/changePassword/ChangePassword'));
const organization = localStorage.getItem("organization")

function Layout(props) {
  var classes = useStyles();
  var layoutState = useLayoutState();
  const dispatch = useDispatch();
  usePageTracking();
  
  useEffect(()=>{
    if(organization){
      let devicesHealthApi = `/${organization}/devices-health/`
      dispatch(getDevicesHealth({url : devicesHealthApi}))
    }
    //eslint-disable-next-line
  },[props, organization])

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
        <div className={classes.fakeToolbar} />
          <Suspense fallback={<div style={{display : 'flex', justifyContent : 'center', alignItems : 'center', marginTop : '5%'}}> <CircularProgress size={24}/> </div>}>
          <Switch>
            <Route path="/app/dashboard">
                <Dashboard />
            </Route>
            <Route path="/app/alarms">
                  <Alarms/>
            </Route>
            <Route path="/app/triggers">
                  <Triggers/>
            </Route>
            <Route path="/app/processedData">
                <ProcessedData />
            </Route>
            <Route path="/app/deviceManagement/manageDevices">
                <DeviceManagement />
            </Route>
            <Route path="/app/deviceManagement/addDevice">
                <AddDevice />
            </Route>
            <Route path="/app/deviceManagement/editDevice/:deviceId">
                <EditDevice />
            </Route>
            <Route path="/app/deviceSummary/:deviceId">
                <DeviceSummary/>
            </Route>
            <Route path="/app/changePassword">
                <ChangePassword />
            </Route>
            <Route path="/app/CustomDashboards/:selectedDashboardKey">
                <CustomDashboards />
              </Route>
          </Switch>
          </Suspense>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
