import { combineReducers, configureStore } from '@reduxjs/toolkit'
import alarmsSlice from './alarmsSlice'
import configurationSlice from './configurationSlice'
import devicesSlice from './devicesSlice'
import deviceGroupsSlice from './deviceGroupsSlice'
import customDashboardsSlice from './customDashboardsSlice'
import feedsSlice from './feedsSlice'
import usersSlice from './usersSlice'
import processedDataSlice from './processedDataSlice'
import weatherSlice from './weatherSlice'
import { APIStatus } from './APIStatus'
import organizationsSlice from './organizationsSlice'
import feedRulesSlice from './feedRulesSlice'
import triggersSlice from './triggersSlice'
import commentsSlice from './commentsSlice'
import resetPasswordSlice from './resetPasswordSlice';

const rootReducer = (state, action) => {
    if (action.type === APIStatus.closeSession) {
        state = undefined
    }
    return combinedReducer(state, action)
}

const combinedReducer = combineReducers({
    devicesSlice: devicesSlice,
    deviceGroupsSlice: deviceGroupsSlice,
    feedsSlice: feedsSlice,
    usersSlice: usersSlice,
    processedDataSlice: processedDataSlice,
    triggersSlice : triggersSlice,
    alarmsSlice: alarmsSlice,
    weatherSlice: weatherSlice,
    configurationSlice: configurationSlice,
    organizationsSlice: organizationsSlice,
    feedRulesSlice: feedRulesSlice,
    resetPasswordSlice : resetPasswordSlice,
    commentsSlice: commentsSlice,
    customDashboards: customDashboardsSlice
})

export const Store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: true
})

