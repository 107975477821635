import axios from "axios";
import React from "react";

var LayoutStateContext = React.createContext();
var LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LayoutProvider({ children }) {
  var [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: false
  });
  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState() {
  var context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}

function useLayoutDispatch() {
  var context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}

export function getDashboardSettings(dispatch) {
  return axios.get(`/${localStorage.getItem("organization")}/dashboards`).then(res => {
    let dList = res.data.results
    dispatch({ type: "GET_DASHBOARD_LIST", payload: dList });
    let configDashboard = dList.map(d => {
      if (d.data && d.data.custom_config) {
        return d.data.custom_config
      }
      return undefined;
    })
    configDashboard = configDashboard.filter(f => f !== undefined)
    if (configDashboard && configDashboard.length > 0) {
      // getDevicesHealth(dispatch, configDashboard[0])
      dispatch({ type: "GET_DASHBOARD_SETTINGS", payload: configDashboard[0] });
    }
    else {
      // getDevicesHealth(dispatch, null)
      dispatch({ type: "GET_DASHBOARD_SETTINGS", payload: null });
    }
  }).catch(err => {
    dispatch({ type: "GET_DEVICES_HEALTH_ERROR", payload: err });
  });
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar };

// ###########################################################
function toggleSidebar(dispatch) {
  dispatch({
    type: "TOGGLE_SIDEBAR",
  });
}
