import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export default makeStyles(theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    overflowY: 'overlay',
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(4) + 40,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
   sidebarList: {
    //marginTop: theme.spacing(6),
    paddingLeft : theme.spacing(1),
    //paddingRight : theme.spacing(2)
  }, 
  mobileBackButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
  //   [theme.breakpoints.up("md")]: {
  //     display: "none",
  //   },
   },
}));
