import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import axios from "axios";
import Themes from "./themes";
import App from "./App";
import './index.css';
// import ReactGA from "react-ga4";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { config } from './config';
import { Provider } from "react-redux";
import { Store } from './app/Store'
import { SnackbarProvider } from 'notistack';
import { ConfirmDialogProvider } from "./context/ConfirmDialogContext";

// ReactGA.initialize("G-N2LHWKHM1Z");

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common["Content-Type"] = "application/json";
const token = localStorage.getItem("id_token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "JWT " + token;
}


ReactDOM.render(
  <Provider store={Store}>
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={3000}>
      <LayoutProvider>
        <UserProvider>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />
            <ConfirmDialogProvider>
              <App />
            </ConfirmDialogProvider>
          </ThemeProvider>
        </UserProvider>
      </LayoutProvider>
    </SnackbarProvider>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();