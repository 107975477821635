import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { APIStatus } from './APIStatus'

const initialState = {
    isLoading : 'loading',
    triggers: null,
    addTriggerStatus: APIStatus.idle,
    addTriggerReceipt: null,
    addTriggerError: null,
    updateTriggerStatus: APIStatus.idle,
    updateTriggerReceipt: null,
    updateTriggerError: null,
    deleteTriggerStatus: APIStatus.idle,
    deleteTriggerReceipt: null,
    deleteTriggerError: null,
}

export const getTriggers = createAsyncThunk('getTriggers', async (payload) => {
    const response = await axios.get(payload.url);
    return response;
})

export const addTrigger = createAsyncThunk('addTrigger', async (payload) => {
    const response = await axios.post(payload.url, payload.data);
    return response;
})

export const updateTrigger = createAsyncThunk('updateTrigger', async (payload) => {
    const response = await axios.patch(payload.url, payload.data);
    return response;
})

export const deleteTrigger = createAsyncThunk('deleteTrigger', async (payload) => {
    const response = await axios.delete(payload.url);
    return response;
})

const feedsSlice = createSlice({
    name: 'triggers',
    initialState,
    reducers: {
        reset(state, action){
            state.feeds = null;
            state.deviceFeeds = null;
        },
        resetaddTrigger(state, action){
            state.addTriggerStatus =  APIStatus.idle;
            state.addTriggerReceipt =  null;
            state.addTriggerError = null;
        },
        resetupdateTrigger(state, action){
            state.updateTriggerStatus =  APIStatus.idle;
            state.updateTriggerReceipt =  null;
            state.updateTriggerError = null;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getTriggers.pending, (state, action) => {
                state.isLoading = APIStatus.loading;
            })
            .addCase(getTriggers.fulfilled, (state, action) => {
                state.isLoading = APIStatus.loaded;
                state.triggers = action.payload ? action.payload.data.results :  null;
            })
            .addCase(addTrigger.pending, (state, action) => {
                state.addTriggerStatus = APIStatus.loading;
            })
            .addCase(addTrigger.fulfilled, (state, action) => {
                state.addTriggerStatus = APIStatus.loaded;
                state.addTriggerReceipt = action.payload;
                state.addTriggerError = null;
            })
            .addCase(addTrigger.rejected, (state, action) => {
                state.addTriggerStatus = APIStatus.failed;
                state.addTriggerError = action.error;
            })
            .addCase(updateTrigger.pending, (state, action) => {
                state.updateTriggerStatus = APIStatus.loading;
            })
            .addCase(updateTrigger.fulfilled, (state, action) => {
                state.updateTriggerStatus = APIStatus.loaded;
                state.updateTriggerReceipt = action.payload;
                state.updateTriggerError = null;
            })
            .addCase(updateTrigger.rejected, (state, action) => {
                state.updateTriggerStatus = APIStatus.failed;
                state.updateTriggerError = action.error;
            })
            .addCase(deleteTrigger.pending, (state, action) => {
                state.deleteTriggerStatus = APIStatus.loading;
            })
            .addCase(deleteTrigger.fulfilled, (state, action) => {
                state.deleteTriggerStatus = APIStatus.loaded;
                state.deleteTriggerReceipt = 'Deleted Successfully';
                state.deleteTriggerError = null;
            })
            .addCase(deleteTrigger.rejected, (state, action) => {
                state.deleteTriggerStatus = APIStatus.failed;
                state.deleteTriggerError = action.error;
            })
    }
})

export const { load, resetaddTrigger, resetupdateTrigger } = feedsSlice.actions

export default feedsSlice.reducer