import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import * as Icons from "@material-ui/icons";
import useStyles from "./styles";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddIcon from '@material-ui/icons/Add';
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { whitelabelConfig } from  "../../whitelabelConfig/whitelabelConfig";
import { useDispatch, useSelector } from "react-redux";
import { getcustomDashboards } from "../../app/customDashboardsSlice";

const structure = [
  { id: 0, type: "divider" },
  { id: 1, label: "Dashboard", link: "/app/dashboard", icon: <Icons.Home /> },
  { id: 2, label: "Alarms", link: "/app/alarms", icon: <Icons.NotificationsActive /> },
  { id: 3, label: "Trigger Settings", link: "/app/triggers", icon: <Icons.Mail />  },
  { id: 4, label: "Processed Data", link: "/app/processedData", icon: <Icons.Book /> },
  {
    id: 5,
    label: "Device Management",
    link: "/app/deviceManagement/manageDevices",
    icon: <Icons.Settings />,
  },
  { id: 6, type: "divider" },
  // { id: 6, label: "Custom Dashboard", link: "/app/customDashboards", icon: <Icons.Widgets /> },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  const dispatch = useDispatch()
  const organization = localStorage.getItem("organization")
  const username = localStorage.getItem('email');
  const currentOwner = organization + '-' + username;
  const customDashboards = useSelector((state) => state.customDashboards.customDashboards)

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var layoutState = useLayoutState();
  const [customDashboardList, setcustomDashboardList] = useState(null)


  // local
  var [isPermanent, setPermanent] = useState(true);
  const [logoImage, setlogoImage] = useState(null)
  const [logoWidth, setlogoWidth] = useState(41)
  const [logoHeight, setlogoHeight] = useState(40)
  const siteDomain = localStorage.getItem('siteDomain')

  useEffect(() => {
    if(whitelabelConfig[siteDomain] && whitelabelConfig[siteDomain] !== undefined ){
      setlogoImage(whitelabelConfig[siteDomain].logo)
      setlogoHeight(whitelabelConfig[siteDomain].logoHeight / 2)
      setlogoWidth(whitelabelConfig[siteDomain].logoWidth / 2 )
    } else{
      setlogoImage(`${process.env.PUBLIC_URL}/icon.png`)
      setlogoHeight(40)
      setlogoWidth(41)
    }
  },[siteDomain])

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    if (customDashboards) {
      setcustomDashboardList(customDashboards)
    }
  }, [customDashboards])


  useEffect(() => {
    dispatch(getcustomDashboards({organization}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  let CustomDashboard = {
    id: 6, label: 'Custom Dashboards', icon: <DashboardIcon />,
    children: [{ id: 999, label: 'Add New Dashboard', link: `/app/CustomDashboards/new`, icon: <AddIcon /> }]
  };
  if (customDashboardList) {
    CustomDashboard.children = CustomDashboard.children.concat(customDashboardList.filter(d=>{
      if(d.public){
        return true;
      } else {
        if(d.owner === currentOwner){
          return true;
        } else {
          return false;
        }
      }
    }).map(d => {
      return { id: d.id, label: d.name, link: `/app/CustomDashboards/${d.key}`, icon: <DashboardIcon /> }
    }))
  }

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      {/* <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div> */}
      <div className={classes.mobileBackButton}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}>
          {layoutState.isSidebarOpened ? (
            <Icons.Close
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }} style={{ color: "rgb(7, 71, 166)" }}
            />
          ) : (
            <Icons.Dehaze
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }} style={{ color: "rgb(7, 71, 166)" }}
            />
          )}
        </IconButton>

      </div>
      {isSidebarOpened === true ?
        <div style={{ marginTop: "5%" }}>
          <img style={{ marginLeft: "15%" }} alt="icon" src={logoImage} width={logoWidth} height={logoHeight} />
          <Typography style={{ marginTop: "5%", float: "right", marginRight: "25%", fontFamily: "'Karla', sans-serif", fontWeight: "bold" }} >Hi, {localStorage.getItem("first_name")}</Typography>
        </div>
        : null}
      <List className={classes.sidebarList}>
        {structure.map((link, i) => (
          <SidebarLink
            key={i}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
        {CustomDashboard &&
          <SidebarLink
            key={CustomDashboard.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            openbyDefault={true}
            {...CustomDashboard}
          />
        }
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
