import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { normalize, schema } from 'normalizr';
import { APIStatus } from './APIStatus'

const initialState = {
    devices: null,
    devicesNormalized: null,
    devicesMetas: null,
    devicesHealth : null,
    deviceComments : null,
    isLoading : 'loading',
    addDeviceStatus: APIStatus.idle,
    addDeviceReceipt: null,
    addDeviceError: null,
    updateDeviceStatus: APIStatus.idle,
    updateDeviceReceipt: null,
    updateDeviceError: null,
    deleteDeviceStatus: APIStatus.idle,
    deleteDeviceReceipt: null,
    deleteDeviceError: null,
    deviceDetailsStatus: APIStatus.idle,
    deviceDetails: null,
    deviceDetailsError: null,
    deviceCloneStatus: APIStatus.idle,
    deviceCloneReceipt: null,
    deviceCloneError: null,
    updateDeviceConfigStatus: APIStatus.idle,
    updateDeviceConfigReceipt: null,
    updateDeviceConfigError: null,
    deleteDeviceImageStatus: APIStatus.idle,
    deleteDeviceImageReceipt: null,
    deleteDeviceImageError: null,
    csvFeedStatus: APIStatus.idle,
    csvFeed: null,
    csvFeedError: null,
    csvDetailStatus: APIStatus.idle,
    csvDetail: null,
    csvDetailError: null,
    processedDataStatus: APIStatus.idle,
    processedData: null,
    processedDataError: null,
    processedDataDetailStatus: APIStatus.idle,
    processedDataDetail: null,
    processedDataDetailError: null,
    deviceFeedDataViewFilterState: null
}

export const getDevices = createAsyncThunk('getDevices', async (payload) => {

    const response = await axios.get(payload.url);
    const { results, count } = response.data;
  
    if (count <= 100) {
        return { data: { results } };
    }
    const requests = [];
    for (let j = 1; j < count / 100; j++) {
        requests.push(axios.get(`${payload.url}&offset=${j * 100}`));
    }

    const dataArr = await Promise.all(requests).then((responses) =>
        responses.reduce((acc, res) => acc.concat(res.data.results), results)
    );

    return { data: { results: dataArr } };


    // let offsetValue = 0;
    // const response = await axios.get(`${payload.url}&offset=${offsetValue}`);
    // const { results, count } = response.data;
    // if (count > 100) {
    //     const requests = [];
    //     for (let j = 1; j < count / 100; j++) {
    //         offsetValue += 100;
    //         requests.push(axios.get(`${payload.url}&offset=${offsetValue}`));
    //     }
    //     const dataArr = await Promise.all(requests).then((responses) =>
    //         responses.reduce((acc, res) => acc.concat(res.data.results), results)
    //     );
    //     return { data: { results: dataArr } };
    // } else {
    //     return { data: { results } };
    // }
})

export const addDevice = createAsyncThunk('addDevice', async (payload) => {
    const response = await axios.post(`/${payload.organization}/devices`, payload.data);
    return response;
})

export const updateDevice = createAsyncThunk('updateDevice', async (payload) => {
    const response = await axios.patch(`/${payload.organization}/devices/${payload.id}`, payload.data);
    return response;
})

export const deleteDevice = createAsyncThunk('deleteDevice', async (payload) => {
    const response = await axios.delete(`/${payload.organization}/devices/${payload.id}`);
    return response;
})

export const getDevicesMetas = createAsyncThunk('getDevicesMetas', async (payload) => {
    const response = await axios.get(payload.url);
    const { results, count } = response.data;
  
    if (count <= 100) {
        return { data: { results } };
    }
    const requests = [];
    for (let j = 1; j < count / 100; j++) {
        requests.push(axios.get(`${payload.url}&offset=${j * 100}`));
    }

    const dataArr = await Promise.all(requests).then((responses) =>
        responses.reduce((acc, res) => acc.concat(res.data.results), results)
    );
    return { data: { results: dataArr } };
})

export const getDevicesHealth = createAsyncThunk('getDevicesHealth', async (payload) => {
    const response = await axios.get(payload.url);
    return response;
})

export const getDeviceDetails = createAsyncThunk('getDeviceDetails', async (payload) => {
    const response = await axios.get(`/${payload.data.organization}/devices/${payload.data.id}`);
    return response;
})

export const getDeviceComments = createAsyncThunk('getDeviceComments', async (payload) => {
    const response = await axios.get(payload.url);
    return response;
})

export const updateDeviceClone = createAsyncThunk('updateDeviceClone', async (payload) => {
    const response = await axios.post(`/${payload.organization}/devices/${payload.id}/clone`, payload.data);
    return response;
})

export const updateDeviceConfig = createAsyncThunk('updateDeviceConfig', async (payload) => {
    const response = await axios.patch(`/${payload.organization}/devices/${payload.id}`, payload.data);
    return response;
})

export const deleteDeviceImage = createAsyncThunk('deleteDeviceImage', async (payload) => {
    const response = await axios.delete(`/${payload.organization}/devices/${payload.deviceId}/delete-image/${payload.id}`);
    return response;
})

export const getCSVFeed = createAsyncThunk('getCSVFeed', async (payload) => {
    const response = await axios.post(`/${payload.organization}/csvdumps`, payload.data);
    return response;
})

export const getCSVDetail = createAsyncThunk('getCSVDetail', async (payload) => {
    const response = await axios.get(`/${payload.organization}/csvdump-details/${payload.id}`);
    return response;
})

export const getProcessedData = createAsyncThunk('getProcessedData', async (payload) => {
    const response = await axios.get(`/${payload.organization}/get-processed-data?device=${payload.id}&file_type=${payload.type}`);
    return response;
})

export const getProcessedDataDetail = createAsyncThunk('getProcessedDataDetail', async (payload) => {
    const response = await axios.get(`/${payload.organization}/get-processed-data/${payload.id}`);
    return response;
})

const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        reset(state, action){
            // state.devices = null
            // state.devicesMetas = null
            state = {...initialState}
        },
        resetAddDevice(state, action){
            state.addDeviceStatus =  APIStatus.idle;
            state.addDeviceReceipt = null;
            state.addDeviceError = null;
        },
        resetUpdateDevice(state, action){
            state.updateDeviceStatus =  APIStatus.idle;
            state.updateDeviceReceipt = null;
            state.updateDeviceError = null;
        },
        resetDeleteDevice(state, action){
            state.deleteDeviceStatus =  APIStatus.idle;
            state.deleteDeviceReceipt = null;
            state.deleteDeviceError = null;
        },
        resetDeviceClone(state, action){
            state.deviceCloneStatus =  APIStatus.idle;
            state.deviceCloneReceipt = null;
            state.deviceCloneError = null;
        },
        resetUpdateDeviceConfig(state, action){
            state.updateDeviceConfigStatus =  APIStatus.idle;
            state.updateDeviceConfigReceipt = null;
            state.updateDeviceConfigError = null;
        },
        resetDeleteDeviceImage(state, action){
            state.deleteDeviceImageStatus =  APIStatus.idle;
            state.deleteDeviceImageReceipt = null;
            state.deleteDeviceImageError = null;
        },
        resetCsvDetail(state, action){
            state.csvFeedStatus =  APIStatus.idle;
            state.csvFeed = null;
            state.csvFeedError = null;
            state.csvDetailStatus =  APIStatus.idle;
            state.csvDetail = null;
            state.csvDetailError = null;
        },
        resetProcessedDataDetail(state, action){
            state.processedDataStatus =  APIStatus.idle;
            state.processedData = null;
            state.processedDataError = null;
            state.processedDataDetailStatus =  APIStatus.idle;
            state.processedDataDetail = null;
            state.processedDataDetailError = null;
        },
        resetDeviceDetails(state, action){
            state.deviceDetailsStatus = APIStatus.idle;
            state.deviceDetails = null;
            state.deviceDetailsError = null;
        },
        setDeviceFeedDataViewFilterState(state, action){
            state.deviceFeedDataViewFilterState = action.payload;
        },
        resetDeviceFeedDataViewFilterState(state){
            state.deviceFeedDataViewFilterState = null;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getDevices.pending, (state, action) => {
                state.isLoading = APIStatus.loading;
            })
            .addCase(getDevices.fulfilled, (state, action) => {
                state.devices = action.payload ? action.payload.data.results :  null;
                const devicesNormalized = new schema.Entity('devicesNormalized', {}, { idAttribute: 'device_id' });
                const normalizedData = normalize(action.payload ? action.payload.data.results : [], [devicesNormalized]);
                state.devicesNormalized = normalizedData;
            })
            .addCase(getDevicesMetas.pending, (state, action) => {
                state.isLoading = APIStatus.loading;
            })
            .addCase(getDevicesMetas.fulfilled, (state, action) => {
                state.isLoading = APIStatus.loaded;
                state.devicesMetas = action.payload ? action.payload.data.results :  null;
            })
            .addCase(getDevicesHealth.pending, (state, action) => {
                state.isLoading = APIStatus.loading;
            })
            .addCase(getDevicesHealth.fulfilled, (state, action) => {
                state.isLoading = APIStatus.loaded;
                state.devicesHealth = action.payload ? action.payload.data :  null;
            })
            .addCase(getDeviceComments.pending, (state, action) => {
                state.isLoading = APIStatus.loading;
            })
            .addCase(getDeviceComments.fulfilled, (state, action) => {
                state.isLoading = APIStatus.loaded;
                state.deviceComments = action.payload ? action.payload.data.results :  null;
            })
            .addCase(addDevice.pending, (state, action) => {
                state.addDeviceStatus = APIStatus.loading;
            })
            .addCase(addDevice.fulfilled, (state, action) => {
                state.addDeviceStatus = APIStatus.loaded;
                state.addDeviceReceipt = action.payload ? action.payload.data :  null;
            })
            .addCase(addDevice.rejected, (state, action) => {
                state.addDeviceStatus = APIStatus.failed;
                state.addDeviceError = action.error;
            })
            .addCase(updateDevice.pending, (state, action) => {
                state.updateDeviceStatus = APIStatus.loading;
            })
            .addCase(updateDevice.fulfilled, (state, action) => {
                state.updateDeviceStatus = APIStatus.loaded;
                state.updateDeviceReceipt = action.payload ? action.payload.data :  null;
            })
            .addCase(updateDevice.rejected, (state, action) => {
                state.updateDeviceStatus = APIStatus.failed;
                state.updateDeviceError = action.error;
            })
            .addCase(deleteDevice.pending, (state, action) => {
                state.deleteDeviceStatus = APIStatus.loading;
            })
            .addCase(deleteDevice.fulfilled, (state, action) => {
                state.deleteDeviceStatus = APIStatus.loaded;
                state.deleteDeviceReceipt = 'Deleted Successfully';
            })
            .addCase(deleteDevice.rejected, (state, action) => {
                state.deleteDeviceStatus = APIStatus.failed;
                state.deleteDeviceError = action.error;
            })
            .addCase(getDeviceDetails.pending, (state, action) => {
                state.deviceDetailsStatus = APIStatus.loading;
            })
            .addCase(getDeviceDetails.fulfilled, (state, action) => {
                state.deviceDetailsStatus = APIStatus.loaded;
                state.deviceDetails = action.payload ? action.payload.data :  null;
                if(action.meta.arg.customAction === 'update'){
                    state.devices = state.devices.map(d=>{
                        if(d.id===action.payload.data.id){
                            return {
                                ...action.payload.data
                            }
                        }
                        return d;
                    })
                }
                
            })
            .addCase(getDeviceDetails.rejected, (state, action) => {
                state.deviceDetailsStatus = APIStatus.failed;
                state.deviceDetailsError = action.error;
            })
            .addCase(updateDeviceClone.pending, (state, action) => {
                state.deviceCloneStatus = APIStatus.loading;
            })
            .addCase(updateDeviceClone.fulfilled, (state, action) => {
                state.deviceCloneStatus = APIStatus.loaded;
                state.deviceCloneReceipt = action.payload ? action.payload.data :  null;
            })
            .addCase(updateDeviceClone.rejected, (state, action) => {
                state.deviceCloneStatus = APIStatus.failed;
                state.deviceCloneError = action.error;
            })
            .addCase(updateDeviceConfig.pending, (state, action) => {
                state.updateDeviceConfigStatus = APIStatus.loading;
            })
            .addCase(updateDeviceConfig.fulfilled, (state, action) => {
                state.updateDeviceConfigStatus = APIStatus.loaded;
                state.updateDeviceConfigReceipt = action.payload ? action.payload.data :  null;
            })
            .addCase(updateDeviceConfig.rejected, (state, action) => {
                state.updateDeviceConfigStatus = APIStatus.failed;
                state.updateDeviceConfigError = action.error;
            })
            .addCase(deleteDeviceImage.pending, (state, action) => {
                state.deleteDeviceImageStatus = APIStatus.loading;
            })
            .addCase(deleteDeviceImage.fulfilled, (state, action) => {
                state.deleteDeviceImageStatus = APIStatus.loaded;
                state.deleteDeviceImageReceipt = 'Deleted Successfully';
            })
            .addCase(deleteDeviceImage.rejected, (state, action) => {
                state.deleteDeviceImageStatus = APIStatus.failed;
                state.deleteDeviceImageError = action.error;
            })
            .addCase(getCSVFeed.pending, (state, action) => {
                state.csvFeedStatus = APIStatus.loading;
            })
            .addCase(getCSVFeed.fulfilled, (state, action) => {
                state.csvFeedStatus = APIStatus.loaded;
                state.csvFeed = action.payload ? action.payload.data :  null;
            })
            .addCase(getCSVFeed.rejected, (state, action) => {
                state.csvFeedStatus = APIStatus.failed;
                state.csvFeedError = action.error;
            })
            .addCase(getCSVDetail.pending, (state, action) => {
                state.csvDetailStatus = APIStatus.loading;
            })
            .addCase(getCSVDetail.fulfilled, (state, action) => {
                state.csvDetailStatus = APIStatus.loaded;
                state.csvDetail = action.payload ? action.payload.data :  null;
            })
            .addCase(getCSVDetail.rejected, (state, action) => {
                state.csvDetailStatus = APIStatus.failed;
                state.csvDetailError = action.error;
            })
            .addCase(getProcessedData.pending, (state, action) => {
                state.processedDataStatus = APIStatus.loading;
            })
            .addCase(getProcessedData.fulfilled, (state, action) => {
                state.processedDataStatus = APIStatus.loaded;
                state.processedData = action.payload ? action.payload.data :  null;
            })
            .addCase(getProcessedData.rejected, (state, action) => {
                state.processedDataStatus = APIStatus.failed;
                state.processedDataError = action.error;
            })
            .addCase(getProcessedDataDetail.pending, (state, action) => {
                state.processedDataDetailStatus = APIStatus.loading;
            })
            .addCase(getProcessedDataDetail.fulfilled, (state, action) => {
                state.processedDataDetailStatus = APIStatus.loaded;
                state.processedDataDetail = action.payload ? action.payload.data :  null;
            })
            .addCase(getProcessedDataDetail.rejected, (state, action) => {
                state.processedDataDetailStatus = APIStatus.failed;
                state.processedDataDetailError = action.error;
            })
    }
})

export const { reset, resetAddDevice, resetUpdateDevice, resetDeviceClone, resetUpdateDeviceConfig, resetDeleteDeviceImage, resetCsvDetail, resetProcessedDataDetail, resetDeviceDetails, setDeviceFeedDataViewFilterState, resetDeviceFeedDataViewFilterState } = devicesSlice.actions

export default devicesSlice.reducer